<template>
  <div>
    <div class="loading-spinner" v-if="show">
      <div class="loading-spinner-div" style="margin-top:auto;height:100%">
        <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" :color="$root.colorLoading"/>
      </div>
    </div>
    <b-card>
      <div class="row border-bottom pb-5">
        <div class="col-md-10">
          <div class="d-flex py-1">
            <span class="fontWeightBold">عنوان کالا : </span>
            <span class="mr-1">{{ product.title }}</span>
          </div>
          <div class="d-flex py-1">
            <span class="fontWeightBold">وضعیت : </span>
            <span class="mr-1">{{ product.status ? 'فعال' : 'غیر فعال' }}</span>
          </div>
          <div class="d-flex py-1">
            <span class="fontWeightBold">دسته بندی : </span>
            <span class="mr-1">{{ product.category ? product.category.name : 'ندارد' }}</span>
          </div>
        </div>
        <div class="col-md-2">
          <a :href="product.image" target="_blank" style="width: 200px; height: 150px;">
            <img :src="product.image" class="rounded w-100 h-100">
          </a>

        </div>
      </div>
      <div class="mt-3">
        <h5 class="fontWeightBold">تنوع ها</h5>
        <b-table responsive striped outlined hover :fields="fields" :items="varieties">
          <template v-slot:cell(status)="data">
            <span class="badge badge-success" v-if="data.item.status == 'available'">موجود</span>
            <span class="badge badge-light" v-else-if="data.item.status == 'unavailable'">ناموجود</span>
            <span class="badge badge-warning" v-else-if="data.item.status == 'inactive'">غیرفعال</span>
            <span class="badge badge-info" v-else-if="data.item.status == 'soon'">به زودی</span>
          </template>
          <template v-slot:cell(price)="data">
            {{ data.item.pivot.price != null ? numberFormat(data.item.pivot.price) : '' }}
          </template>
          <template v-slot:cell(discount)="data">
            {{ data.item.discount != null ? numberFormat(data.item.discount) : '-' }}
          </template>
          <template v-slot:cell(quantity)="data">
            <div class="d-flex justify-content-start align-items-center">
              <!-- <span class=" cursor-pointer increment p-0 text-center">
                  <i class="fas fa-plus"></i>
              </span> -->
              <!-- <span >
              <b-form-input v-model="data.item.quantity_send" style="height: 30px; line-height: 20px;"></b-form-input>
          </span> -->
              <router-link :to="{name: 'product.store' , params:{id:data.item.id}}">
                <div class="border rounded cursor-pointer" style="height: 30px; line-height: 5px;">
                  {{ data.item.pivot.in_stock }}
                </div>
              </router-link>

              <!-- <span class=" cursor-pointer increment p-0 text-center">
                  <i class="fas fa-minus"></i>
              </span> -->
            </div>
          </template>
          <template v-slot:cell(add)="data">
            <router-link :to="{name: 'product.store' , params:{id:data.item.id}}"
                         class="btn btn-success">
              تغییر موجودی
            </router-link>
          </template>
          <template v-slot:cell(edit)="data">
            <button class="btn btn-primary" @click="editPrice(data.index)">
              <i class="fa fa-edit"></i>
            </button>
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="varieties == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </div>
    </b-card>
    <b-modal id="edit-price-modal" title="ویرایش قیمت" hide-footer>
      <form id="price-edited" @submit.prevent="priceEdited">
        <b-form-group>
          <label for="edit-price-store">قیمت <i class="fas fa-asterisk text-danger"
                                                style="font-size: 0.5rem;"></i></label>
          <b-form-input id="edit-price-store" title="price" v-model="price"></b-form-input>
        </b-form-group>
        <input type="hidden" name="seller_id" :value="edit_price.seller_id">
        <div class="clearfix">
          <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
          <b-button class="float-left" variant="white" type="button"
                    @click="$root.$emit('bv::hide::modal', 'edit-price-modal')">بستن
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import mixins from '../mixins/mixins'

export default {
  mixins: [mixins],
  data() {
    return {
      product: {},
      title: '',
      url: '/api/seller/products',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'fullTitle',
          label: 'عنوان'
        },
        {
          key: 'price',
          label: 'قیمت (تومان)'
        },
        {
          key: 'quantity',
          label: 'تعداد'
        },
        {
          key: 'sales_count',
          label: ' فروش'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'discount',
          label: 'تخفیف (تومان)'
        },
        {
          key: 'add',
          label: 'تغییر موجودی'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
      ],
      varieties: [],
      edit_price: {},

    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.show = true
      this.$axios.get(this.$root.baseUrl + this.url + `/${this.$route.params.id}`)
          .then(response => {
            this.product = response.data.data.product
            this.title = this.product.title
            document.title = this.title
            this.show = false
          })
      this.$axios.get(this.$root.baseUrl + this.url + `/${this.$route.params.id}/varieties`)
          .then(response => {
            this.varieties = response.data.data.varieties

          })

    }
  },
  methods: {
    editPrice(index) {
      this.edit_price = {}
      let item = window.clone(this.varieties[index])
      item.index = index
      this.edit_price = item
      this.price = this.edit_price.pivot.price
      this.$root.$emit('bv::show::modal', 'edit-price-modal')
    },
    priceEdited() {
      this.disabled = true
      let org_price = this.price <= 999 ? this.price : this.price.replace(/\D/g, "");

      this.$axios.post(this.$root.baseUrl + `/api/seller/varieties/${this.edit_price.id}/change-price`, {
        price: org_price
      })
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.varieties[this.edit_price.index].pivot.price = this.price
            this.$root.$emit('bv::hide::modal', 'edit-price-modal')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
    },
  }
}
</script>
<style scoped>
.box-shadow {
  box-shadow: 5px 9px 25px 6px #0a0a0a21;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer !important;
}

.increment {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.increment .fas {
  font-size: 11px;
}
</style>
