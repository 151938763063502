export default {
    data() {
        return {
            items: [],
            edit:{},
            show: false,
            search: false,
            disabled: false,
            searchParams: {},
            title:'',
            price:0,
            editItemValue:{}
        }
    },
    mounted() {
        document.title = this.title
        window.$('body').removeClass('active')
        if (this.$root.getCookie("sellerAuthorization")) {
            this.$axios.defaults.headers.common["Authorization"] = this.$root.getCookie(
                "sellerAuthorization"
            );
        }
    },
    watch: {
        price: function(newValue) {
            let englishNumber = this.convertToEnglishNumber(newValue);
            const result = englishNumber
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.price = result;
        },
    },
    methods: {
        numberFormat (num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        convertToEnglishNumber(input) {
            var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
            input = "" + input + "";
            return input.replace(/[\u06F0-\u06F9]/g, function(m) {
            return persianDigits.indexOf(m);
            });
        },
        loadItems(page = 1) {
            this.show = true
            this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page).then(response => {
                this.items = response.data
                this.show = false
            })
        },
        showItem(item){
            this.editItemValue=item
            this.$root.$emit('bv::show::modal', 'show-modal')
        },
    },
    filters: {
        persianDate (date) {
            return window.moment(date).format('HH:mm jYYYY/jMM/jDD ')
        },
        date(date) {
            var now = new Date();
            let start = window.moment(date); // some random moment in time (in ms)
            let end = window.moment(now); // some random moment after start (in ms)
            let diff = end.diff(start);
            let time = window.moment.utc(diff).format("H:m:ss");
            let dayDiff = diff / (1000 * 60 * 60 * 24);
            let hour = time.split(":")[0];
            let min = time.split(":")[1];
            let second = time.split(":")[2];
            if ((hour == 0 && min == 0 && second <= 59 && dayDiff <= 1) || diff < 0) {
              return " چند ثانیه پیش";
            } else if (hour == 0 && min <= 59 && dayDiff <= 1) {
              return min + " دقیقه پیش";
            } else if (hour >= 1 && 24 >= hour && dayDiff <= 1) {
              return "حدود " + hour + " ساعت پیش";
            } else if (dayDiff > 1) {
              return window.moment(date).format("dddd jDD jMMMM jYYYY");
            }
          },
    },
}